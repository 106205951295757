/* eslint-disable no-undef */

export const sendClooset = (data) => {
  console.log('sendClooset: ', data)
  /* Do not edit this part */
  APPRL = window.APPRL || {};
  APPRL.Tracking = APPRL.Tracking || {};
  APPRL.Tracking.URL = APPRL.Tracking.URL || {};
  APPRL.Tracking.Sale={};

  /* Conversion data */
  APPRL.Tracking.Sale.order_id=data.id; // e-commerce responsability
  APPRL.Tracking.Sale.order_value=data.order_value; // e-commerce responsability
  APPRL.Tracking.Sale.currency='BRL';

  try { APPRL.Tracking.run() } 
  catch(e) {
    console.error(e)
  }

}
